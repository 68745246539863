export const fetchUserLocation = async () => {
  console.log("Fetching location...");
  try {
    const response = await fetch("https://ipapi.co/json/");
    console.log("Response status:", response.status);
    const data = await response.json();
    console.log("Data received:", data);

    if (!data || !data.city || !data.country || !data.country_name) {
      console.warn("Incomplete location data:", data);
      return null; // Return null or an empty object
    }

    return {
      city: data.city,
      country: data.country_name,
      countryCode: data.country,
    };
  } catch (error) {
    console.error("Error fetching location:", error);
    return null; // Return null or an empty object
  }
};
