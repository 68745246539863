import React, { useContext, useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { errorHandler } from "../../../../api/Api";
import endpoints, { HANDLE_API_REQUEST } from "../../../../api/EndPoints";
import { MainContent } from "../../../../App";
import Location from "../Location";
import { fetchUserLocation } from "../../../../utils/fetchUserLocation";

function YourLocation({ profileData }) {
  const { t } = useTranslation();
  const { showtoast, myown_data, setFreshReload } = useContext(MainContent);
  const { location } = myown_data?.data || {};
  const profileID = myown_data?.data?.profileid;
  const [countryOrigin, setCountryOrigin] = useState(
    location?.countryoforiginid
  );
  const [country, setCountry] = useState(location?.countryofresidenceid || "");
  const [longitude, setLongitude] = useState(location?.gpslongitude);
  const [latitude, setLatitude] = useState(location?.gpslatitude || "");
  const [cityName, setCityName] = useState(location?.alternatecityname || "");

  const getTranslateId = (countryCode) => {
    const country = profileData.country.find(
      (c) => c.countrycode === countryCode
    );
    return country ? country.translistid : null; // Return null if no match is found
  };

  useEffect(() => {
    const getLocation = async () => {
      const userLocation = await fetchUserLocation();
      if (!userLocation) return;
      
      if (!location?.alternatecityname) {
        setCityName(`${userLocation.city}, ${userLocation.country}`);
      }

      if (!location?.countryofresidenceid) {
        const id = getTranslateId(userLocation.countryCode);
        setCountry(id);
      }
    };

    if (!location?.alternatecityname || !location?.countryofresidenceid) {
      getLocation();
    }
  }, []);

  const handleSaveLocation = () => {
    const body = JSON.stringify({
      locationId: profileID,
      gpsLongitude: longitude,
      gpsLatitude: latitude,
      alternatecityname: cityName,
      cityId: null,
      countryOfOriginId: countryOrigin,
      countryOfResidenceId: country,
    });
    HANDLE_API_REQUEST.POST(endpoints.SAVE_LOCATION, body)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setFreshReload(true);
        showtoast(result);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <Accordion.Item className="my-2 rounded-md bg-white shadow-md" eventKey="2">
      <Accordion.Header>
        {" "}
        <h1 className="text-xl text-sr font-medium  pb-2 flex items-center  w-full">
          {t("Your_Location")}
        </h1>
      </Accordion.Header>
      <Accordion.Body>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center gap-3">
          <div className="pt-4">
            <h2 className="text-sr text-sm font-medium pb-1 border-b mb-1">
              {t("Country_Of_Origin")}
            </h2>
            <select
              // className="form-select form-select-md mb-3 mt-2 py-2 w-full"
              className={`form-select form-select-md mb-3 mt-2 py-2 w-full ${
                countryOrigin === "" ||
                countryOrigin === undefined ||
                countryOrigin === null
                  ? "bg-red-100"
                  : "bg-green-100 "
              }`}
              aria-label=".form-select-lg example"
              id="countryOrigin"
              value={countryOrigin}
              onChange={(e) => setCountryOrigin(e.target.value)}
            >
              <option value={""}>{t("Select_Country")}</option>
              {profileData.country.map((item) => (
                <option key={item.translistid} value={item.translistid}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="pt-4">
            <h2 className="text-sr text-sm font-medium pb-1 border-b mb-1">
              {t("CountryOfResidence")}
            </h2>
            <select
              //className="form-select form-select-md mb-3 mt-2 py-2 w-full"
              className={`form-select form-select-md mb-3 mt-2 py-2 w-full ${
                country === "" || country === undefined || country === null
                  ? "bg-red-100"
                  : "bg-green-100 "
              }`}
              aria-label=".form-select-lg example"
              id="country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            >
              <option value="">{t("Select_Country")}</option>
              {profileData.country.map((item) => (
                <option key={item.translistid} value={item.translistid}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="pt-4">
            <div className="flex items-center justify-between">
              <h2 className="text-sr text-sm font-medium pb-1 border-b">
                {t("Location_City")}
              </h2>
              <p className="text-gray-600">{cityName}</p>
            </div>
            <div className="mb-3 mt-2 py-2">
              <Location
                setLongitude={(value) => setLongitude(value)}
                setLatitude={(value) => setLatitude(value)}
                setCityName={(value) => setCityName(value)}
                savedAddress={cityName ?? ""}
                useColors={true}
              />
            </div>
          </div>
        </div>

        <div className="w-full items-end flex justify-end">
          <button
            type="button"
            onClick={handleSaveLocation}
            className="text-white px-3 mt-4 rounded py-2 bg-sr text-md font-bold hover:bg-pr transition ease-linear duration-300 cursor-pointer"
          >
            {t("Save")}
          </button>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default YourLocation;
