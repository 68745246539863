import React, { useContext, useEffect, useState, useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Recaptcha from "react-recaptcha";
import { ToastContainer } from "react-toastify";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MainContent } from "../App";
import "./style.css";
import { makeStyles } from "@mui/styles";
import Swal from "sweetalert2";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  labelRoot: {
    right: 0,
  },
  shrink: {
    transformOrigin: "top right!important",
  },
}));

const Register = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { checkleft, language } = useContext(MainContent);
  const [name, setName] = useState("");
  const [gender, setGender] = useState(false);
  const [age, setAge] = useState(20);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verified, setVerified] = useState(false);
  const [acceptterms, setAcceptterms] = useState(false);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [accepttermsError, SetAccepttermsError] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [holdToShow, setHoldToShow] = useState(false);
  const recaptchaRef = useRef(null);
  let agelist = [];
  for (let i = 18; i < 100; i++) {
    agelist.push(i);
  }

  const handleRecaptchaVerify = (token) => {
    setRecaptchaToken(token);
  };
  // sign up
  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!handleValidation()) {
      setIsSubmitting(false);
      return;
    }

    setIsSubmitting(true);
    let date = new Date();
    const year = date.getFullYear();
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ApiKey", process.env.REACT_APP_API_KEY);
    myHeaders.append("Accept-Language", language || "en");

    const raw = JSON.stringify({
      email: email,
      password: password,
      confirmationPassword: password,
      acceptTerms: acceptterms,
      siteId: 1,
      gender: gender,
      birthDate: `${year - age}-${
        date.getMonth() + 1
      }-${date.getDate()}T11:19:00.979Z`,
      Username: name,
      recaptchaToken: recaptchaToken,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${process.env.REACT_APP_URI}identity/register`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.succeeded) {
          window.location.href = "/email-confirmation";
        } else {
          swalController(result.errors[0], "", "warning");
          resetRecaptcha();
        }
      })
      .catch((error) => {
        resetRecaptcha();
        console.log(error);
      });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      document.getElementById("focus").focus();
    }, 10);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const handleKeyPress = (event) => {
    const regex = /[@]/g;
    const key = String.fromCharCode(event.keyCode || event.which);
    if (regex.test(key)) {
      event.preventDefault();
    }
  };

  function validatePassword() {
    const lowercaseRegex = /^(?=.*[a-z])/;
    const uppercaseRegex = /^(?=.*[A-Z])/;
    const specialCharRegex = /^(?=.*[^\da-zA-Z])/;
    const numberRegex = /^(?=.*\d)/;
    const minLength = 8;

    let isValid = true;
    let errorMessage = "";

    if (!lowercaseRegex.test(password)) {
      errorMessage = t("passwordErrorLowercaseMessage");
      isValid = false;
    } else if (!uppercaseRegex.test(password)) {
      errorMessage = t("passwordErrorUppercaseMessage");
      isValid = false;
    } else if (!specialCharRegex.test(password)) {
      errorMessage = t("passwordErrorSpecialCharMessage");
      isValid = false;
    } else if (!numberRegex.test(password)) {
      errorMessage = t("passwordErrorNumberMessage");
      isValid = false;
    } else if (password.length < minLength) {
      errorMessage = t("passwordErrorLengthMessage");
      isValid = false;
    }

    setPasswordError(errorMessage);

    return isValid;
  }

  const handleValidation = () => {
    let isValid = true;

    if (!name) {
      setNameError(t("nameErrorMessage"));
      isValid = false;
    } else {
      setNameError("");
    }
    if (name.length < 6 || name.length > 10) {
      setNameError(t("NameErrorLengthMessage"));
      isValid = false;
    } else {
      setNameError("");
    }
    if (!email) {
      setEmailError(t("emailErrorMessage"));
      isValid = false;
    } else if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      setEmailError(t("emailErrorInvalidMessage"));
      isValid = false;
    } else {
      setEmailError("");
    }

    if (isValid) {
      isValid = validatePassword();
    }

    if (acceptterms === false) {
      SetAccepttermsError(t("accepttermsErrorMessage"));
      isValid = false;
    } else {
      SetAccepttermsError("");
    }

    return isValid;
  };

  const changeLanguagrToArabic = () => {
    const rootElement = document.getElementById("RegistrationForm");
    rootElement.style.cssText = `
    direction: rtl;
    font-family: 'Noto Kufi Arabic', sans-serif;
    font-size: 15px;
  `;
  };

  useEffect(() => {
    if (language === "ar") {
      changeLanguagrToArabic();
    }
  }, []);

  useEffect(() => {
    if (acceptterms) {
      SetAccepttermsError("");
    }
  }, [acceptterms]);

  const swalController = (title, message, icon) => {
    Swal.fire({
      title: title,
      text: message,
      confirmButtonText: t("Ok"),
      confirmButtonColor: "#032E42",
      icon: icon,
      timer: 10000,
    });
  };

  // create a reset function
  const resetRecaptcha = () => {
    setVerified(false);

    if (recaptchaRef.current !== null) {
      recaptchaRef.current.reset();
    }

    setIsSubmitting(false);
  };

  const handleMouseDown = () => {
    setHoldToShow(true);
    setShowPassword(true);
  };

  const handleMouseUp = () => {
    setHoldToShow(false);
    setShowPassword(false);
  };

  return (
    <div className="w-100 register p-3 rounded">
      <ToastContainer />
      <form
        onSubmit={(e) => handleFormSubmit(e)}
        dir={checkleft ? "ltr" : "rtl"}
        noValidate="noValidate"
        autoComplete="off"
        id="RegistrationForm"
      >
        <div className="mb-3">
          {/* <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="button-tooltip">
                {t("Register_Name_ToolTip")}
              </Tooltip>
            }
          > */}
          <TextField
            required
            style={
              checkleft
                ? { width: "100%" }
                : { width: "100%", direction: "rtl" }
            }
            InputLabelProps={
              !checkleft
                ? {
                    classes: {
                      root: classes.labelRoot,
                      shrink: classes.shrink,
                    },
                  }
                : {}
            }
            inputProps={{
              maxLength: 10,
              minLength: 6,
              onKeyPress: handleKeyPress,
            }}
            type="text"
            label={t("Register_Name")}
            id="focus"
            onChange={(e) => {
              setName(e.target.value);
            }}
            variant="standard"
            color="success"
            error={!!nameError}
            helperText={
              <p className={`text-${language === "ar" ? "right" : "left"}`}>
                {nameError}
              </p>
            }
          />
          {/* </OverlayTrigger> */}
        </div>

        <div className="flex items-center justify-between flex-wrap">
          <div>
            <FormControl
              variant="standard"
              color="success"
              sx={{ minWidth: 120 }}
            >
              <InputLabel
                id="demo-simple-select-standard-label"
                className={
                  language === "ar"
                    ? "w-full flex items-center justify-end px-0 "
                    : "w-full flex items-center justify-start px-0"
                }
              >
                {t("I_Am_A")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                label={t("I_Am_A")}
              >
                <MenuItem value={true}>{t("Male")}</MenuItem>
                <MenuItem value={false}>{t("Female")}</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl
              variant="standard"
              color="success"
              sx={{ minWidth: 120 }}
            >
              <InputLabel
                id="demo-simple-select-standard-label"
                className={
                  language === "ar"
                    ? "w-full flex items-center justify-end px-0"
                    : "w-full flex items-center justify-start px-0"
                }
              >
                {t("Profile_Looking_For")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                label={t("Profile_Looking_For")}
              >
                <MenuItem value={true}>{t("Female")}</MenuItem>
                <MenuItem value={false}>{t("Male")}</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div>
            <FormControl
              variant="standard"
              color="success"
              sx={{ minWidth: 120 }}
            >
              <InputLabel
                id="demo-simple-select-standard-label"
                className={
                  language === "ar"
                    ? "w-full flex items-center justify-end px-0"
                    : "w-full flex items-center justify-start px-0"
                }
              >
                {t("My_Age")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={(e) => setAge(e.target.value)}
                label={t("My_Age")}
                value={age}
                sx={{
                  "& .MuiSelect-select": {
                    paddingTop: 1,
                    paddingBottom: 1,
                  },
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{ sx: { height: 300, padding: 0 } }}
              >
                {agelist.map((item, ind) => (
                  <MenuItem key={ind} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="mt-3">
          {/* <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="button-tooltip">{t("Email_ToolTip")}</Tooltip>
            }
          > */}
          <TextField
            required
            type="email"
            style={
              checkleft
                ? { width: "100%" }
                : { width: "100%", direction: "ltr" }
            }
            // InputLabelProps={
            //   !checkleft
            //     ? {
            //         classes: {
            //           root: classes.labelRoot,
            //           shrink: classes.shrink,
            //         },
            //       }
            //     : {}
            // }
            InputLabelProps={{
              style: {
                direction: language === "ar" ? "rtl" : "ltr", // Label direction based on language
              },
              classes:
                language === "ar"
                  ? {
                      root: classes.labelRoot,
                      shrink: classes.shrink,
                    }
                  : {},
            }}
            label={t("Email")}
            onChange={(e) => setEmail(e.target.value)}
            // onInvalid={(e) => e.target.setCustomValidity(t("Email_Required"))}
            // onInput={(e) => e.target.setCustomValidity("")}
            variant="standard"
            color="success"
            error={!!emailError}
            helperText={
              <p className={`text-${language === "ar" ? "right" : "left"}`}>
                {emailError}
              </p>
            }
          />
          {/* </OverlayTrigger> */}
        </div>

        <div className="mt-3">
          {/* <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="button-tooltip">{t("Password_ToolTip")}</Tooltip>
            }
          > */}
          <TextField
            required
            style={
              checkleft
                ? { width: "100%" }
                : { width: "100%", direction: "ltr" }
            }
            InputLabelProps={{
              style: {
                direction: language === "ar" ? "rtl" : "ltr", // Label direction based on language
              },
              classes:
                language === "ar"
                  ? {
                      root: classes.labelRoot,
                      shrink: classes.shrink,
                    }
                  : {},
            }}
            //type="password"
            type={showPassword ? "text" : "password"}
            label={t("Password")}
            onChange={(e) => setPassword(e.target.value)}
            variant="standard"
            color="success"
            error={!!passwordError}
            helperText={
              <p className={`text-${language === "ar" ? "right" : "left"}`}>
                {passwordError}
              </p>
            }
            InputProps={{
              endAdornment: password && (
                <InputAdornment position="end">
                  <IconButton
                    vi
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onTouchStart={handleMouseDown}
                    onTouchEnd={handleMouseUp}
                  >
                    {showPassword || holdToShow ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* </OverlayTrigger> */}
        </div>
        <div className="form-check mt-3">
          <div className="flex items-center flex-wrap gap-1">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexCheckDefault"
              required
              onChange={(e) => setAcceptterms(e.target.checked)}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                flexDirection: checkleft ? "row" : "row-reverse",
              }}
            />
            <label
              className="form-check-label  text-sr text-sm "
              style={
                checkleft ? { marginRight: "0px" } : { marginRight: "25px" }
              }
              htmlFor="flexCheckDefault"
            >
              {t("Privacy")}
            </label>
            <span
              onClick={() => props.setModalShow(true)}
              className=" capitalize  text-sm cursor-pointer text-pr border-b hover:text-sr font-bold"
            >
              {t("Policy")}
            </span>
          </div>
          {!!accepttermsError ? (
            <label
              className="form-check-label  text-sr text-sm "
              style={
                checkleft ? { marginRight: "0px" } : { marginRight: "25px" }
              }
              htmlFor="flexCheckDefault"
            >
              <span className="text-red-600">
                {t("accepttermsErrorMessage")}
              </span>
            </label>
          ) : (
            ""
          )}
        </div>
        <div className="mt-3 flex items-center w-full justify-center">
          <Recaptcha
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPCHA_KEY}
            id="register"
            render="explicit"
            elementID="register"
            hl={language}
            verifyCallback={(token) => {
              setVerified(true);
              handleRecaptchaVerify(token);
            }}
            onErrored={() => {
              resetRecaptcha();
            }}
          />
        </div>
        <div className="mt-3 flex items-center justify-center w-full gap-3">
          {verified ? (
            <button
              type="submit"
              className=" border bg-pr px-3  text-sr  py-2 text-sm font-medium rounded "
              disabled={isSubmitting}
            >
              {isSubmitting ? t("Submitting") : t("Create_Acount")}
            </button>
          ) : (
            <button
              type="button"
              className=" border px-3 text-sr hover:bg-pr py-2 text-sm font-medium rounded"
            >
              {t("Create_Acount")}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default Register;
